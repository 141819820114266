@media only screen and (min-width: 768px) {
  #hj-survey-lbl-1 {
    font-size: 2em !important;
    line-height: 1.25em !important;
  }

  #hj-survey-lbl-1 + div {
    font-size: 1.5em !important;
    line-height: 1.25em !important;
  }
}

._hj-widget-container > div > div > div > div > form > div {
  min-height: inherit !important; /** boooooo */
}
